<template>
  <v-form>
    <v-row>
      <v-col
        cols="12"
      >
        <v-text-field
          v-model="referral.program_name"
          outlined
          label="Program name"
          prepend-icon="mdi-text-box-check"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
      >
       <v-select
         v-model="business"
         :items="[business]"
         item-text="name"
         item-value="id"
         label="Select business"
         outlined
         prepend-icon="mdi-domain"
         hide-details="auto"
         disabled
         :error="referral.business == '' && showError"
       ></v-select>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
       <v-select
         v-model="referral.status"
         :items="statusitems"
         item-text="text"
         item-value="id"
         label="Status"
         outlined
         prepend-icon="mdi-list-status"
         hide-details="auto"
       ></v-select>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
       <v-select
         v-model="referral.type"
         :items="typeitems"
         item-text="text"
         item-value="id"
         label="Type"
         outlined
         prepend-icon="mdi-check"
         hide-details="auto"
       ></v-select>
      </v-col>
      <!-- <v-col
        cols="12"
        sm="6"
      >
       <v-select
         v-model="referral.contact"
         :items="contactitems"
         item-text="contact_source"
         item-value="id"
         label="Select contact"
         outlined
         prepend-icon="mdi-file-document-edit"
         hide-details="auto"
         :error="referral.contact == '' && showError"
       ></v-select>
      </v-col> -->
      <v-col
        cols="12"
      >
        <v-textarea
          v-model="referral.notes"
          outlined
          label="Notes"
          prepend-icon="mdi-pen"
          hide-details="auto"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-btn
      @click="updateCurrentReferral()"
      x-large
      color="primary"
      absolute
      bottom
      right
    >
      Update Referral
    </v-btn>
  </v-form>
</template>
<script>
  import { mapActions } from 'vuex';

  export default {
    name: 'UpdateReferral',
    components: {

    },
    data() {
      return {
        referral: {
          program_name: '',
          business: '',
          contact: '',
          status: null,
          type: null,
          notes: null,
        },
        statusitems: [
          { id: 0, text:'Active'},
          { id: 1, text:'Closed'}
        ],
        typeitems: [
          { id: 0, text:'Internal'},
          { id: 1, text:'External'}
        ],
        business: '',
        showError: false
      }
    },
    computed: {
      // businessitems() {
      //   return this.$store.getters.businesses
      // },
      contactitems() {
        return this.$store.getters.contacts
      }
    },
    created() {
      //this.getContacts()
      this.getReferral({id: this.$route.params.id})
      .then((response) => {
        this.referral = Object.assign({}, this.referral, response.data )
        this.getBusiness({id: this.referral.business})
        .then((bresponse) => {
          console.log('response', bresponse)
          this.business = bresponse.data.name
        }, (error) => {
          console.log('error', error)
        });
      }, (error) => {
        console.log('error', error)
      });
    },
    methods: {
      ...mapActions(['getContacts', 'getBusiness', 'updateReferral', 'getReferral']),
      updateCurrentReferral() {
        this.overlay = true;
        this.updateReferral(this.referral)
        .then(() => {
          this.overlay = false;
        }, () => {
          this.showError = true
          this.overlay = false;
        });
      }
    }
  }
</script>
<style lang="scss" scoped>

</style>
